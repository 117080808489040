@import "../Supervision.less";

.client-action {
  color: @gold;

  .ant-col {
    cursor: pointer;
    :hover {
      color: @primary-color;
    }
  }

  a {
    color: @gold !important;
  }

  .small {
    font-size: @font-size-sm+2;
    margin-right: @margin-xs;

    .ant-col {
      margin-right: @margin-xs;
    }
  }

  .medium {
    font-size: @font-size-base;
    margin-right: @margin-xs;

    .ant-col {
      margin-right: @margin-xs;
    }
  }

  .large {
    margin-left: @margin-lg - 2;
    font-size: @font-size-lg-plus;

    .ant-col {
      margin-right: @margin-xs;
    }
  }
}

