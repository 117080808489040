//.ant-typography {
//  font-family: 'Red Hat Display W' !important;
//}

.h-fork-dark {
  cursor: pointer;
  text-align: center;
  background: #3F8DFA;
  font-family: 'Red Hat Display W' !important;
  color: #FFFFFF;

  /*&:hover {
    background: #FFFFFF;
    color: #475a6b;
  }*/
}

.h-fork-white {
  text-align: center;
  cursor: pointer;
  background: #e0ad60;
  color: #475a6b;
  font-family: 'Red Hat Display W' !important;
  &:hover {
    background: #FFFFFC;
  }
}



