@import "../../../htheme.less";


.holistico {
  .tutorial-list-of-contents {
    background: white;
    border: 1px @color-extra-light-grey solid;
    padding: @space-xlg;

    .tutorial-list-of-contents-divider {
      display: block;
      margin: @space-lg 0 @space-sm @space-lg;
      text-transform: uppercase;
      color: @color-light-grey;
      letter-spacing: 1px;
      font-weight: 400;

      &:hover {
        cursor: default;
      }
    }
  }

  .tutorial-page {
    margin-bottom: @space-xlg;

    .tutorial-level-1-content, .tutorial-level-2-content {
      max-width: 50em;
    }

    .tutorial-level-2-content {
      margin-bottom: @space-xxlg;
    }
  }
}

