@import "../../theme.less";

.big-search-container {
  .big-search {
    background: rgba(255, 255, 255, 0.5) !important;
    border-radius: 4px !important;
    margin-bottom: @padding-md;

    input {
      font-size: x-large;
      background: transparent !important;
    }
  }

  .big-search-safari {
    background: rgba(255, 255, 255, 0.5) !important;
    border-radius: 4px !important;

    input {
      font-size: large;
      background: transparent !important;
    }
  }

  &:not(.bordered) {
    .big-search {
      border: 0 !important;

      input {
        border: 0 !important;
      }
    }

    .big-search-safari {
      border: 0 !important;

      input {
        border: 0 !important;
      }
    }
  }
}

