@import "../Supervision.less";

.config-inactive-flag {
    cursor: pointer;
    padding: 5px;
    border-radius: 2px;
}

.config-inactive-flag:hover {
    background-color: rgb(235, 235, 235);
}

.config-active-flag {
    padding: 5px;
    border-radius: 2px;
    background-color: rgb(206, 206, 206);
}

.supervision-config-item {
    margin-right: 20px;
    margin-bottom: 20px;
    min-width: 285px;
}


.supervision-config-limitshow {
    color: #cccccc !important;
    
    :hover {
        color: @primary-color;
    }
}



