@import "../../../../../theme.less";

.market-assumptions input {
  font-size: x-large !important;
  background: rgba(255, 255, 255, 0.5) !important;
  border-radius: 4px !important;
  width: 100% !important;
}

.market-assumptions {
  margin-bottom: 16px;
}

.market-assumptions .ant-input-number {
  width: 100% !important;
}

.market-assumptions label {
  color: @breadcrumb-last-item-color;
}

.market-assumptions {
  .selected-block {
    border: 1px @gold solid;

    &:hover {
      border: 1px @gold solid;
    }
  }

  .selectable-block {
    border: 1px solid @primary-color !important;
  }

  .ant-card-meta-title {
    color: @text-color;
    font-weight: 1200;
  }

  .ant-card-body {
    padding-top: 0px;
  }

  .ant-list-item-meta-description {
    color: @text-color;
  }
}

