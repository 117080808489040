@import "../../theme.less";

.stress {
  font-size: @font-size-lg;
  font-weight: bold;

  .ant-table-thead > tr > th {
    position: relative;
    color: @text-color;
    font-weight: 600;
    text-align: left;
    background: transparent;
    border-bottom: 1px solid @text-color;
    transition: background 0.3s ease;

    .ant-table-cell {
      border: 0px;
      font-weight: 600;
    }
  }

  .stress-positive {
    color: @success-color;
    font-weight: bold;
    margin-bottom: 0;
  }

  .stress-negative {
    color: @error-color;
    margin-bottom: 0;
  }

}

.stress-period-positive {
  .ant-card-meta-title {
    font-size: x-large;
  }
}

.stress-period-negative {
  .ant-card-meta-title {
    font-size: x-large;
  }

  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0px;
  }
}

.ant-statistic.stress-positive {
  .ant-statistic-content {
    color: @green-7;
    font-size: @font-size-xlg
  }

  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0px;
  }

  .ant-statistic-title {
    font-size: 24px;
  }

  .ant-statistic-content-suffix {
    font-size: @font-size-lg
  }
}

.ant-statistic.stress-negative {
  .ant-statistic-content {
    color: @red;
    font-size: @font-size-xlg
  }

  .ant-statistic-title {
    font-size: 24px;
  }

  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0px;
  }

  .ant-statistic-content-suffix {
    font-size: @font-size-lg
  }
}

