@import "@root/theme.less";

.flag-row-item {
  height: 60px;
  font-size: 20px;

  .ant-tag {
    font-size: 0.9em;
  }
}

.flag-client-link {
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.flag-client-link:hover {
  text-decoration: underline 1px @gold;
  color: @gold;
}

.flag-client-link:active {
  color: @gold;
}

.flag-tree {
  font-size: 20px !important;


  .ant-tree {
    .ant-tree-node-content-wrapper {
      cursor: auto !important;
      user-select: inherit;
    }
  }

}

.ant-tree .ant-tree-node-content-wrapper {
  cursor: default !important;
  user-select: inherit;
}

.supervision-container {
  background: @white;
  padding: @padding-md;

  .text {
    font-size: @font-size-base;
  }

  .report-constructor {
    .supervision-row-header {
      height: 60px;
    }

    .supervision-row-content {
      // margin: @margin-md;
      padding: @padding-sm;
      padding-top: 0px;
      margin-top: 4px;
    }
  }
}

.representatives-tree {
  tr > td:not(:first-child) {
    .ant-tag {
      margin-right: 0;
    }

    .ant-badge-status-text {
      display: none !important;
    }
  }
}

