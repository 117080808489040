@import "../../theme.less";

.allocation-summary-performances {
  .data-row {
    background: transparent;
  }

  .category-row:hover {
    background: @signet-gold !important;
    color: @text-color;
    td {
      background: @signet-gold !important;
    }
  }
  
  .category-row {
    background: @signet-blue !important;
    color: @white;
    font-weight: bold;
    font-size: @font-size-lg;
    td {
      background: @signet-blue !important;
      color: @white;
      font-weight: bold;
    }
  }
}

.select-portfolios-transfer {
  .ant-transfer-list {
    display: flex;
    flex-direction: column;
    width: 310px;
    height: 500px;
    border: 1px solid #cfcfcf;
    border-radius: 0px;
  }
}

