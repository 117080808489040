@import "../../../../../htheme.less";


.holistico {
  .models-carousel {
    button.slick-prev, button.slick-next {
      color: @color-light-grey;

      &:hover, &:focus {
        color: @color-light-grey;
      }
    }

    .slick-list {
      margin: 0 12px;
    }
  }
}


