@import "../theme.less";

.loadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loadingBox-local {
  justify-content: center;
  align-items: center;
}

.main-container {
  min-height: 500px;
}

