@import "../../../../htheme.less";


.holistico {
  .proposal-view {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      min-height: @font-size-xmega;

      h3.ant-typography {
        font-size: @font-size-xmega;
        font-variant: small-caps;
        line-height: 1;
        margin: 0;
        color: @color-gold;
      }
    }

    .mega-menu {
      li {
        max-width: fit-content;
      }
    }

    .proposal-item-header {
      //margin: @space-xxxlg 0 0 calc(@space-lg + @space-input-padding);
      margin: 0 0 0 @space-lg;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(100% - (@space-lg + @space-input-padding));
      min-height: 46px;

      h3 {
        font-size: @font-size-xxxlg;
        margin-bottom: 0;
        margin-right: calc(@space-lg + @space-input-padding);
        //color: @color-blue;
      }

      .ant-menu {
        flex-grow: 1;
        background: none;
        border: none;

        .ant-menu-item:first-child {
          margin-left: -20px;
        }
      }

      .ant-menu:not(:first-child) {
        .ant-menu-item:first-child {
          margin-left: calc(@space-lg + @space-input-padding - 20px - 10px);
        }
      }

      .ant-menu:not(:first-child):before {
        content: "/";
        color: @color-light-grey;
        display: inline-block;
        width: 10px;
      }

      h3.ant-typography, .ant-menu {
        animation: fadeIn 0.5s ease-in-out;
        -webkit-animation: fadeIn 0.5s ease-in-out;
      }


      .page-header-hint:before {
        content: "/";
        color: @color-light-grey;
        display: inline-block;
        margin-right: @space-xlg;
        width: 10px;
      }
    }

    .proposal-item-header.secondary {
      h3 {
        color: @color-grey;
      }

      .ant-menu {
        .ant-menu-item {
          color: @color-light-grey;
        }

        .ant-menu-item:hover {
          color: @color-grey;
        }

        .ant-menu-item:hover:after {
          border-color: @color-grey;
        }

        .ant-menu-item-selected {
          color: @color-grey;
        }

        .ant-menu-item-selected:after {
          border-color: @color-grey;
        }
      }
    }

    .page-content {
      min-height: 700px;
    }

    .portfolio-chart-label {
      position: relative;
      display: flex;
      padding: @space-input-padding;
      margin-bottom: @space-lg;
      box-sizing: border-box;
      border: 1px white solid;

      animation: fadeIn 1s ease-in-out;
      -webkit-animation: fadeIn 1s ease-in-out;

      h4.ant-typography {
        font-size: @font-size-base;
        font-weight: 350;
        color: @signet-gold;

        line-height: 1;
        margin: 0;
      }

      h4.ant-typography:after {
        position: absolute;
        left: @space-input-padding;
        //bottom: -1px;
        bottom: 5px;
        content: "";
        height: 1px;
        width: calc(100% - @space-input-padding - @space-input-padding);
        background: @signet-gold;
      }

      &:hover {
        color: @color-grey;
        background: @color-selection-background;
        cursor: pointer;
      }
    }

    .portfolio-chart-label.compact {
      display: inline-flex;
      background: @color-selection-background;
      margin-left: @space-input-padding;
      padding: @space-base;

      h4:after {
        content: none;
      }
    }
  }
}


