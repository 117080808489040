@import "../../../theme.less";

.user-edit-icon {
    font-size: 1.3em;
    cursor: pointer;
}

.user-edit-icon:hover {
    border-bottom: 2px dashed rgb(91, 91, 91);
}
