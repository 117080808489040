@import "theme.less";

@font-face {
  font-family: 'Red Hat Display W';
  src: url("~fonts/RedHatDisplay-VariableFont_wght.ttf") format('truetype');
}

@border-radius-base: 3px;

@font-size-base: 16px;
@font-size-sm: 12px;
@font-size-md: 14px;
@font-size-lg: 18px;
@font-size-xlg: 20px;
@font-size-xxlg: 24px;
@font-size-xxxlg: 30px;
@font-size-mega: 36px;
@font-size-xmega: 48px;
@font-size-xxmega: 60px;
@font-size-xxxmega: 72px;


@space-base: 12px;
@space-sm: 4px;
@space-md: 8px;
@space-lg: 16px;
@space-xlg: 24px;
@space-xxlg: 32px;
@space-xxxlg: 48px;
@space-mega: 64px;
@space-xmega: 96px;
@space-xxmega: 128px;
@space-xxxmega: 192px;
@space-giga: 256px;
@space-xgiga: 384px;
@space-xxgiga: 512px;
@space-xxxgiga: 640px;
@space-input-padding: 12px;

@color-blue: #1890ff;
@color-dark-blue: #475a6b;
@color-light-blue: #93C2F0;
@color-extra-light-blue: #DBEDFF;
@color-super-light-blue: #f8fdff;
@color-selection-background: #e6f7ff;
@color-selection-background-light: #eef7fc;
@color-grey: #4B4B4B;
@color-dark-grey: #141414;
@color-light-grey: #a2a2a2;
@color-extra-light-grey-on-grey: #e3e3e3;
@color-extra-light-grey: #F0F0F0;
@color-gold: #e0ad60;
@color-dark-gold: #bd893a;
@color-light-gold: #f1d7af;
@color-extra-light-gold: #faf7f2;
@color-green: #008000;
@color-indian-red: #CD5C5C;
@color-light-green: #86bd91;
@color-light-red: #f5222d;
@color-warning: #faad14;
@color-positive-green: #49aa19;
@color-negative-red: #f5222d;
@color-crimson: #b23156;


@color-primary: @color-blue;
@color-secondary: @color-grey;
@color-portfolio-original: @signet-blue;
@color-portfolio-proposed: @signet-gold;

@panel-bg: #e8e4de;

@background-color-light: #F6F9FA;
@background-color-blue: #fafdff;


.holistico {
  font-family: "Red Hat Display W", serif;
  font-size: @font-size-base;
  font-weight: 400;
  font-synthesis: style small-caps;
  color: @color-dark-grey;
  &:not(.ant-drawer) {
    background: @background-color-light;
  }

  &.retail {
    .portfolio-position-row {
      background: rgba(255, 255, 255, 0);

      &:hover {
        background: @color-selection-background;
      }
    }
  }

  .ant-layout-header {
    .ant-menu-title-content {
      font-weight: 450;
    }
  }

  // Common styles
  h3.primary {
    //color: @color-primary;
    color: @color-dark-gold;
  }

  h3.secondary {
    color: @color-secondary;
  }

  h3.page-title {
    font-size: @font-size-xxxlg;
    margin-left: calc(@space-lg + @space-input-padding);
    //margin-bottom: @space-sm;
    margin-bottom: 0;
  }

  .ant-modal-title {
    font-size: @font-size-xxlg;
    font-weight: 350;
  }

  .page-content {
    background: white;
    border: 1px @color-extra-light-grey solid;
    padding: @space-lg;

    .settings-container {
      margin: -@space-lg -@space-lg @space-lg -@space-lg;
      padding: @space-xlg @space-lg;
      border-bottom: 1px @color-extra-light-grey solid;
      //background: rgba(147, 116, 64, 0.04);

      .ant-input-number-input {
        //background: @color-super-light-blue;
      }
    }

    h4.section-header {
      font-size: @font-size-xxlg;
      font-weight: 400;
      margin: @space-base @space-input-padding;
      display: flex;
      align-items: center;
      white-space: nowrap;

      &:after {
        content: " ";
        display: block;
        width: 100%;
        position: relative;
        border-top: 1px @color-extra-light-grey solid;
        transform: translateY(50%);
        margin-left: @space-base;
      }

      &:not(:first-child) {
        margin-top: @space-xxxlg;
      }
    }
  }

  .ant-radio-group.nowrap {
    white-space: nowrap;
    overflow: hidden;
  }

  .dark-panel {
    background: @color-dark-blue;
    color: white;

    .ant-btn-secondary {
      background: none;
      color: @color-extra-light-grey;
      border-color: @color-extra-light-grey;;

      &:hover {
        //background: white;
        color: @color-extra-light-blue;
        border-color: @color-extra-light-blue;
      }
    }

    .ant-btn-link {
      color: @color-extra-light-grey;
      &:hover {
        color: @color-extra-light-blue;
      }
    }
  }

  &.ant-modal.dark-header .ant-modal-close {
    color: @color-extra-light-grey;
  }

  .hint {
    //font-size: @font-size-md;
    color: @color-light-grey;
    a {
      color: @color-light-blue;
      &:hover {
        color: @color-blue;
      }
    }
  }

  a.secondary-link {
    //color: @color-light-grey;
    color: @color-grey;
  }

  a.secondary-link:hover {
    color: @color-primary;
  }

  &.no-padding-modal-content .ant-modal-body {
    padding: 0;
  }

  .blured-3 {
    filter: blur(3px);
  }

  .blured-4 {
    filter: blur(4px);
  }

  .transparent-scroll-overflow {
    overflow: scroll;
    position: relative;
  }

  .transparent-scroll-overflow:before {
    content: "";
    display: block;
    position: sticky;
    left: 0;
    top: 0;
    height: 16px;
    width: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }

  .transparent-scroll-overflow:after {
    content: "";
    display: block;
    position: sticky;
    left: 0;
    bottom: 0;
    height: 32px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }

  .fade-in {
    opacity: 0;
    animation-name: fadeIn;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  .fade-in-500 {
    animation: fadeIn 0.5s ease-in-out;
    -webkit-animation: fadeIn 0.5s ease-in-out;
  }

  .fade-in-300 {
    animation: fadeIn 0.3s ease-in-out;
    -webkit-animation: fadeIn 0.3s ease-in-out;
  }

  .fade-in-200 {
    animation: fadeIn 0.3s ease-in-out;
    -webkit-animation: fadeIn 0.3s ease-in-out;
  }

  .selection-bg-on-hover:hover {
    background: @color-selection-background;
  }

  .grey-bg-on-hover:hover {
    background: rgba(0, 0, 0, 0.06);
  }

  .proposal-section-description {
    div.ant-typography:last-child {
      margin-bottom: 0;
    }
  }

  .three-line-ellipsis{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    * {
      display: inline;
    }


    @supports (-webkit-line-clamp: 3) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  // Changes for ant styles
  b {
    font-weight: 600;
  }

  h1.ant-typography,
  h2.ant-typography,
  h3.ant-typography {
    font-weight: 350;
  }

  .ant-btn-text {
    padding-left: @space-md;
    padding-right: @space-md;
  }

  .ant-btn-text:hover {
    color: @color-blue;
    background: none;
    //border: 1px @color-blue solid;
  }

  .ant-empty-description {
    font-size: @font-size-base;
    color: @color-grey;
    line-height: 1;
  }

  .ant-form {
    //.ant-checkbox-wrapper-in-form-item {
    //  .ant-checkbox + span {
    //    color: @color-light-grey;
    //    //font-size: @font-size-md;
    //  }
    //
    //  .ant-checkbox.ant-checkbox-checked + span {
    //    color: @color-grey;
    //  }
    //}

    .ant-collapse,
    .ant-collapse-item,
    .ant-collapse-header,
    .ant-collapse-content {
      border: none;
    }

    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          display: flex;
          align-items: center;
          font-size: @font-size-md;
          font-weight: 600;
          color: @color-grey;
          padding: 0;
          background: white;
        }

        .ant-collapse-header:hover {
          color: @color-blue;
        }

        //.ant-collapse-header:before {
        //  content: "";
        //  flex: 1;
        //  margin-right: @space-xxlg;
        //  height: 1px;
        //  background-color: @color-extra-light-grey;
        //  max-width: calc(16.66666667% - @space-xxlg - 24px);
        //}

        //.ant-collapse-header:after {
        //  content: "";
        //  flex: 1;
        //  margin-left: @space-xxlg;
        //  height: 1px;
        //  background-color: @color-extra-light-grey;
        //}

        .ant-collapse-content-box {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 0;
  }

  .ant-upload-wrapper:not(.ignore-holistico) {
    .ant-upload.ant-upload-drag {
      background: none;
      border-color: @color-light-grey;

      p.ant-upload-drag-icon .anticon {
        color: @color-light-blue;
        font-size: @font-size-mega;
      }

      p.ant-upload-text {
        color: @color-light-blue;
        font-size: @font-size-base;
      }

      p.ant-upload-hint {
        color: @color-light-grey;
        font-size: @font-size-md;
      }
    }

    .ant-upload-drag:hover {
      box-shadow: 0 0 6px 4px @color-extra-light-blue;
    }
  }

  .white-spinner .ant-spin-dot-item {
    background-color: white;
  }
  // Fixes of SPAS legacy
  h1.ant-typography:before,
  h2.ant-typography:before,
  h3.ant-typography:before,
  h3.ant-skeleton-title:before {
    content: "";
  }

  //.risk-indicator {
  //  /*Corner border*/
  //  --b: 1px;   /* thickness of the border */
  //  --c: @color-grey;   /* color of the border */
  //  --w: 25%;  /* width of border */
  //  border: var(--b) solid #0000; /* space for the border */
  //  --_g: #0000 90deg,var(--c) 0;
  //  --_p: var(--w) var(--w) border-box no-repeat;
  //  background:
  //    conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--_g)) 0    0    / var(--_p),
  //    conic-gradient(from 180deg at top    var(--b) right var(--b),var(--_g)) 100% 0    / var(--_p),
  //    conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--_g)) 0    100% / var(--_p),
  //    conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--_g)) 100% 100% / var(--_p);
  //}

}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.holistico-dark-popover {
    .ant-popover-arrow-content::before {
      background: #252F2F;
    }

    .ant-popover-inner {
      background: #252F2F;
    }

    .ant-popover-title {
      color: white;
      border-color: @color-light-grey;
    }

    .ant-popover-inner-content {
      color: white;
      div {
        color: white;
      }
    }
  }

