@import "./../Portfolio";

.full-width-tabs {
    .ant-tabs-nav {
        width: 99%;
        font-size: large;
    }

    .ant-tabs-tab {
        width: 49%;
        text-align: center;
    }
}

