@import "../../../../../htheme.less";


.holistico {
  .portfolio-row-item {
    background: none;
  }

  .portfolio-row-item:hover {
    background: @color-selection-background;
  }

  .page-content {
    .portfolio-container {
      margin: 0 @space-input-padding;
    }

    .portfolio-stats {
      margin: @space-xlg 0;

      .ant-statistic-title {
        margin-bottom: @space-sm;
        font-size: @font-size-base;
      }

      .ant-statistic-content-value {
        font-feature-settings: "tnum", "tnum";
        font-variant: tabular-nums;
        font-size: 22px;
      }
    }
  }

  .accounts-list {
    //margin: 0 @space-input-padding;
    &:not(:first-child) {
      margin-top: @space-xlg;
    }

    .accounts-list-title {
      color: @color-light-grey;
      font-weight: 600;
    }

    .accounts-list-item {
      padding: @space-base;
      border: 1px @color-extra-light-grey solid;
      margin-bottom: @space-base;

      .account-id {
        display: inline-block;
        width: 60px;
        font-weight: 600;
        color: @color-light-grey;
      }

      .account-name {
        font-weight: 600;
      }

      &:hover {
        background: @color-selection-background;
        cursor: pointer;
      }
    }
  }

}


