@import "../../theme.less";

.retirement-options input {
  font-size: x-large;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 45px;
}

.retirement-options .ant-input-number {
  width: 100%;
  font-size: x-large;
  background: rgba(255, 255, 255, 0.5);
  height: 45px;
}
.retirement-options textarea {
  width: 100%;
  font-size: x-large;
  background: rgba(255, 255, 255, 0.5);
}

.retirement-options label {
  color: @breadcrumb-last-item-color;
}

