@import "../../theme.less";

// .add-client-container {
//   .ant-card-head-title {

//   }
// }

.add-client {
  font-size: x-large;
  background-color: transparent;

  input {
    font-size: x-large !important;
    border-radius: 4px !important;
  }
}
.add-client .ant-select-selection {
  background-color: transparent;
}

.add-client-error {
  font-size: 30px;
  color: rgb(189, 57, 48);
}

.add-client-bottom-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
}

.add-client-buttons {
  margin-left: auto;
}

