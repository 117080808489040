.selector-container {
    margin-top: 13px;
    border: 1px solid #e8e8e8;
    border-radius: 2px 2px 0 0;
}

.selector-row {
    padding: 10px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    justify-content: center;
    align-items: center;
}

.selector-loading {
    text-align: center;
    font-size: 1.9em;
}

.selector-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

