@import "../../theme.less";

.client-info-container {
  
  @media print {
    margin-left: -24px;
  }

  .ant-tag {
    box-sizing: border-box;
    margin-top: 8px;
    font-size: @font-size-lg;
    font-weight: bold;
    font-family: monospace;
    font-variant: tabular-nums;
    padding: 2px 12px;
    line-height: 26px;
    white-space: nowrap;
  }
}

.client-info-container .ant-card-bordered {
  border: 0 !important;
}

.client-info-container .ant-card {
  font-size: @font-size-lg;
  background: url('/img/wave-gold.png') no-repeat calc(50% + 200px) 50%;
  @media print {
    color: @primary-color;
  }
}

.client-info-container .ant-card-meta-title {
  overflow: hidden;
  @media print {
    color: @primary-color;
  }
  font-variant: small-caps;
  line-height: 1;

  font-size: @font-size-xlg;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.client-info-container .ant-card-meta-description {
  font-weight: 600;
}

