@import "@root/theme.less";
.Services-factor-group {
  background-color: #EDFFB6;
}

.Value-factor-group {
  background-color: #A3FFA4;
}

.Growth-factor-group {
  background-color: #FF8782;
}

.Safety-factor-group {
  background-color: #93DDA2;
}

.Technical-factor-group {
  background-color: #C5DDFF;
}

.Profitability-factor-group {
  background: none;
}

.Score-factor-group {
  background-color: #f293f5;
}
.Return-factor-group {
  background-color: #dee1d1;
}
.Rating-factor-group {
  background-color: #cdd5ff;
}

.Custom-factor-group {
  background-color: #a7fcff;
}

