@import '../../Portfolio.less';

.dropdown-addStock {
  position: absolute;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
  border-radius: @border-radius-base;
  background: @back-top-color;
  width: 99%;
  z-index: 800;
}

.search-container-addStock {
  background: @background-color-base;
  width: 100%;
  max-height: 30vh;
  overflow: auto;
  z-index: 800;
}

.input-addStock {
  border: none;
  background: none;
  background: rgba(255, 255, 255, 0.5) !important;
  border: 0 !important;
  border-radius: 4px !important;
  min-height: 40px;

  input {
    border: 0 !important;
    font-size: x-large;
    background: transparent !important;
  }
}

.row-addStock {
  padding: 5px 0;
  width: 99%;
  border-top: 1px solid #d1cbcb;
  position: relative;

  :hover {
    background: @item-hover-bg;
  }
}

.stock-row {
  border-top: 1px solid #d1cbcb;
  padding: 5px;
  cursor: pointer;

  :hover {
    background-color: "#000000" !important;
  }

  :first-child {
    border-top: none !important;
  }
}

stock-row:hover {
  background-color: "#000000";
}

.stock-row-nothing-found {
  border-top: 1px solid #d1cbcb;
  padding: 5px;
  width: 100%;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  background-color: "#000000";

  :hover {
    background: '#e4dedc';
  }
}

.addField-addStock {
    padding: 5px 0;
    border-top: 1px solid #d1cbcb;
    display: grid;
    grid-template-columns: 1fr 2fr 4fr 2fr 3fr 1fr;
    align-items: center;
    justify-items: center;

}

.press-addStock{
    border: 1px solid #d1cbcb;
    border-radius: 3px;
    padding: 5px;
    cursor: pointer;
}

.new-button-addStock{
    // border: 1px solid #d1cbcb;
    border-radius: 3px;
    margin: 0 13px;
    background: #1890ff;
    color: white !important;
    cursor: pointer;
}

.new-button-addStock:hover{
    background: #40a9ff;
    box-shadow: 0px 0px 3px 1px rgba(65, 128, 170, 0.432);
}

.creation-container{
    background: white;
    padding: 20px;
    border-radius: 4px;
    min-width: 20vw;
    max-width: 50vw;
}

.creation-container input {
    width: 100% !important;
}

.creation-container .ant-input-number,.ant-calendar-picker,.ant-input-select {
    width: 100% !important;
}

