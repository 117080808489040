@import "../../../htheme.less";


.holistico {

  .ant-empty {
    .ant-empty-description {
      color: @color-light-grey;
    }

    &.loading .ant-empty-description  {
      color: rgba(255, 255, 255, 0);
    }
  }
}

