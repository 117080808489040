@import "../../theme.less";

.portfolio-stats {
  //.ant-statistic {
  //  line-height: 1.1;
  //}
  //
  //.ant-statistic-content {
  //  font-family: 'Red Hat Display';
  //}
  //
  //.ant-statistic-title {
  //  margin-bottom: 4px;
  //  font-size: 20px;
  //}

  .ant-statistic-content-value {
    //font-size: 45px
    border-bottom: 2px solid @gold-5;
  }
}

.portfolio-row-item.benchmark {
  border: 1px solid @gold;
}

.portfolio-row-item {
  align-items: center;
  justify-items: center;
  font-size: @font-size-lg;
  background: @background-color-light;

  //box-shadow: @shadow-1-left;
  border: 1px solid @border-color-base;
  margin-top: @list-item-meta-title-margin-bottom !important;
  padding: @padding-xs;
  border-radius: @border-radius-base;
  cursor: pointer;

  sup {
    font-size: @font-size-base;
    box-shadow: 0 0 0 0;
  }

  .additional-info {
    visibility: hidden;
    color: @text-color-secondary;
  }

  .small-additional-info {
    font-size: @font-size-sm;
    visibility: hidden;
    color: @text-color-secondary;
  }

  &:hover {
    background: @item-hover-bg;

    sup {
      visibility: visible;
    }

    .additional-info {
      visibility: visible;
    }

    .small-additional-info {
      visibility: visible;
    }
  }

  &.portfolio-row-title {
    min-height: 60px;
  }
}

.risk-indicator {
  position: relative;
  box-sizing: border-box;
  border: 2px solid @volcano-1;
  border-radius: @border-radius-base;

  &.risk-indicator-low {
    border-color: @volcano-1;
  }

  &.risk-indicator-medium {
    border-color: @volcano-2;
  }

  &.risk-indicator-high {
    border-color: @volcano-3;
  }

  &:after {
    content: "RISK";
    line-height: 1;
    position: absolute;
    bottom: 0.2em;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.5em;
    font-weight: 300;
    letter-spacing: 0.25em;
    color: #f5a879;
  }

  .risk-indicator-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-variant-numeric: tabular-nums;
    white-space: nowrap;
    background: @primary-1;
  }

  &.ghost {
    border: 1px #a2a2a2 solid;

    &:after {
      color: #a2a2a2;
    }

    .risk-indicator-inner {
      background: none;

      .ant-typography {
        color: #4B4B4B;
      }
    }
  }
}


.loadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.positions-loading {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 30vh;
  font-size: 3em;
}

.loadingTables {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 30vh;
  font-size: 3em;
}

.portfolio-container {
  margin-top: 20px;
}

.portfolio-container > div {
  margin-top: 20px;

}

.noDataBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

.noDataBox > div {
  margin-top: 10px;
}

.portfolio-table {
  border-radius: @border-radius-base;
}

.portfolio-position-row {
  //background: @white;
  border-top: 1px solid @border-color-base;
  min-height: 45px;
  align-items: center;

  .remove-icon {
    visibility: hidden;
    text-align: center;
  }

  .ticker {
    color: @heading-color;
  }

  sup {
    font-size: @font-size-base;
    box-shadow: 0 0 0 1px;
    visibility: hidden;
  }

  .score-risk-negative {
    sup {
      color: @volcano-7;
    }
  }

  .score-risk-positive {
    sup {
      color: @lime-7;
    }
  }

  .new {
    background: @back-top-hover-bg;

    .remove-icon {
      visibility: visible;
    }
  }

  &:hover {
    background: @item-hover-bg;
    cursor: pointer;

    sup {
      visibility: visible;
    }

    .remove-icon {
      visibility: visible;
    }
  }
}

.portfolio-title-risk {
  min-width: 33px;
  width: 15%;
  height: auto;
}

.portfolio-title-name {
  justify-self: left;
  word-wrap: break-word;
  font-size: @card-head-font-size;
  font-weight: 350;
  color: @heading-color;
}

.portfolio-title-secondary {
  justify-self: left;
  word-wrap: break-word;
  font-size: @font-size-base;
  color: @text-color;
  margin-bottom: 0em;
}

.portfolio-paragraph-title {
  justify-self: left;
  word-wrap: break-word;
  font-size: @font-size-base;
  font-weight: @typography-title-font-weight;
  color: @text-color;
  margin-bottom: 0;
}

.portfolio-title-market-value {
  justify-self: end;
}

.portfolio-title-menu {
  border-left: 1px solid @border-color-base;
  text-align: center;
  cursor: pointer;
}

.portfolio-title-model-menu {
  border-left: 1px solid @border-color-base;
  padding-left: 3vmin;
}

.range-slider {
  .ant-slider-handle {
    display: none;
  }

  .ant-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, @red 0%, #86BD91FF 50%, #86BD91FF 100%) !important;
    border-radius: 2px;
    transition: background-color 0.3s;
  }

  .ant-slider-mark-text-active {
    color: rgba(0, 0, 0, 0.65);
    margin-top: 30px;
  }
}

.portfolio-select-modal {
  padding: 5px;
  width: 750px;

  .ant-modal-body {
    padding: 13px !important;
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }
}

.portfolio-full-card {
  //width: 250px;

  .additional-details {
    padding: @padding-sm;
    margin-top: @margin-lg;
    border: 0.5vmin solid @primary-2;
    border-radius: @border-radius-base;
    background: @white;

    .ring-guide-name {
      font-size: @font-size-lg !important;
    }

    .ring-guide-value {
      font-size: @font-size-lg !important;
    }

    .ant-card-body {
      padding-bottom: 12px;
    }
  }
}

.ring-guide-name {
  font-size: @font-size-lg !important;
}

.ring-guide-value {
  font-size: @font-size-lg !important;
}

.portfolio-analysis {
  background: @white;
  padding: @padding-md;
  margin-top: @margin-md;
}


.active-row {
  border: 2px solid #3196B2;
}

