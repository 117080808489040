.flag-row-content-inner .ant-tree {
    background: none;
}

.flag-row-content-inner .ant-tree-show-line .ant-tree-switcher {
    background: none;
    font-size: 1.1em;
}

.supervision-client-inner {
    margin-bottom: 10px;
}

.supervision-report-tree {
    font-size: 1.1em !important;
}

