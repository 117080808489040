@import "@root/theme.less";

.scheduler_logs-table-row {
    cursor: pointer;
}

.scheduler-container {
    background: @white;
    padding: @padding-md;
}
