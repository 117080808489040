@import "../../theme.less";

.questionnaire {
  padding: @padding-lg;

  .steps-content {
    margin-top: @margin-md;
    border: 1px solid @primary-2;
    border-radius: @border-radius-base;
    background-color: @background-color-light;
    min-height: 300px;
    text-align: left;
    padding: @padding-md;
  }

  .steps-action {
    margin-top: 12px;
    text-align: center;
  }

  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    border: 1px solid @primary-2;
    border-radius: @border-radius-base;
    margin-right: 8px;
  }

  .ant-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: @heading-color;
    font-size: 16px;
    font-weight: bolder;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    line-height: unset;
    cursor: pointer;
  }

  .user-slider {
    .ant-slider-rail {
      height: 4px;
      background: @primary-1;
      border-radius: 2px;
    }

    .ant-slider-dot {
      background-color: @background-color-light;
      border: 1px solid @primary-color;
    }

    .ant-slider-handle {
      border: solid 0px #536d70;
    }

    .ant-slider-track {
      background-color: @gold;
    }
  }

  .risk-improve-range {
    width: 30px;
    border-radius: @border-radius-base;
    margin: @margin-md auto 0;
    justify-self: center;


    &.default {

    }

    &.less-risk {
      background: linear-gradient(transparent, transparent 25%, @green 25%, @green 60%, @red 60%, @red 85%, transparent 85%, transparent 100%);
      height: 300px;
    }

    &.current {
      background: linear-gradient(transparent, transparent 15%, @green 15%, @green 57%, @red 57%, @red 87%, transparent 87%, transparent 100%);
      border-radius: 0px;
      height: 300px;
    }

    &.more-profit {
      background: linear-gradient(@green, @green 52%, @red 52%, @red 92%, transparent 92%, transparent 100%);
      height: 300px;
    }


  }

  .risk-improve-column {
    text-align: center;

    .ant-typography {
      //font-weight: bolder;
      font-size: @font-size-lg !important;
      //font-variant: all-small-caps;

      &.positive {
        padding-bottom: @padding-md !important;
        font-weight: bolder;
      }
    }

    .improve-div {
      border: none; //@border-width-base @border-color-base solid;
      margin: @margin-lg;
      padding: @padding-md;
      cursor: pointer;
      border-radius: @border-radius-base;
      background-color: @background-color-base;

      height: 430px;
      align-self: center;

      &:hover {
        border-color: @primary-2;
        background-color: @item-hover-bg;
        border: @border-width-base @border-color-base solid;
      }

      &.current {
        background-color: @item-hover-bg;
        margin-left: 70px;
        margin-right: 70px;
        border: 2px @gold solid;

        &:hover {
          border-color: @primary-2;
          border: @border-width-base @border-color-base solid;
        }
      }

    }
  }
}

