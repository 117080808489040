.ant-spin-fullscreen {
  background-color: #FFFFFF;

  .ant-spin-dot-item {
    background-color: #3F8DFA;
  }

  .ant-spin-text {
    font-size: 18px;
    color: #3F8DFA !important;
  }
}


