@signet-blue: #3196B2;
@signet-gold: #E0AD60;
//move this below import to use
@primary-color: #3196B2;

// antv4 legacy constants
@black: #000000;
@white: #ffffff;
@primary-1: #e6f7ff;
@red-6: #f5222dff;
@green-6: #52c41aff;
@green-7: #86bd91;
@primary-2: #bae7ff;
@primary-4: #69c0ff;
@gold-5: #ffc53d;
@volcano-1: #fff2e8;
@volcano-2: #ffd8bf;
@volcano-3: #ffbb96;
@volcano-7: #fa541c;
@lime-7: #a0d911;
@breadcrumb-last-item-color: fade(@black, 85%);
@heading-color: fade(@black, 85%);
@background-color-base: f5f5f5;
@text-color-secondary: fade(@black, 45%);
@back-top-hover-bg: #eef7fc;
@back-top-color: #ffffff;

@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small
@list-item-meta-title-margin-bottom: @padding-sm;

@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;
@card-head-font-size: @font-size-lg;
@typography-title-font-weight: 600;

@border-width-base: 1px;

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');

@font-face {
  font-family: 'Red Hat Display';
  font-weight: 100 800;
}

@text-color: fade(@black, 85%);
@font-size-base: 16px;
@gold: #E0AD60;

@font-size-xlg: @font-size-base * 3;
@font-size-lg-plus: @font-size-base * 1.25;

@item-hover-bg: @primary-1;
@table-row-hover-bg: @item-hover-bg;
@red: @red-6;
@green: @green-6;
@success-color: rgb(134, 189, 145);
@normal-color: @primary-color;
@error-color: @red;
@highlight-color: @red;

@border-color-base: #cfcfcf;
@border-radius-base: 6px;
@background-color-dark: #1F2830;
@background-color-light: #F6F9FA;
@layout-sider-background: @background-color-dark;

@menu-dark-bg: @background-color-dark;
@layout-trigger-background: @menu-dark-bg;

body {
  margin: 0;
  padding: 0;
  font-family: 'Red Hat Display', sans-serif;
  //-moz-osx-font-smoothing: grayscale;
}

.signet-avatar {
  img {
    border-radius: 50%;
    color: #FADB14;
  }
}

.ant-layout {
  padding: 0;
  background: @background-color-light;
}

.ant-layout-content {
  min-height: 750px;
  max-width: 1300px;
  width: 90%;
  background: @background-color-light url('/img/lines_footer.svg') no-repeat;
  background-position-y: 60px;
  margin: 0 auto;
}

.ant-layout-footer {
  background: @background-color-light;
}

.positive {
  color: @success-color;
}

.negative {
  color: @error-color;
}

.normal {
  background-color: transparent;
  color: @primary-color;
}

.success {
  background-color: @success-color;
}

.exception {
  background-color: @error-color;
}


.font-18 {
  .ant-table-small {
    font-size: 18px !important;
  }
}

.pedantic {
  .ant-table-cell {
    padding: 0px 8px !important;
  }
}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: @background-color-dark;
  transition: all 0.2s;
}

h3:before {
  content: " — ";
  color: @gold;
}


h4.ant-typography.spas:before, .ant-typography.spas h4:before {
  content: " — ";
  color: @primary-4;
}

.ant-statistic-content {
  color: @text-color;
  font-size: 22px;
}

.online_state-card-meta .ant-card-meta-description {
  margin-top: -9px;
}

.form-modal {
  .ant-modal-header {
    padding: @padding-sm;
  }

  .ant-modal-body {
    padding: @padding-sm;

    .ant-form-item {
      margin-bottom: @margin-sm;

      .ant-form-item-explain {
        display: none;
      }
    }
  }
}

div.ant-notification-notice-description {
  white-space: pre-line;
}

.selection-bg-on-hover:hover {
  background: @primary-1;
}

.grey-bg-on-hover:hover {
  background: rgba(0, 0, 0, 0.06);
}

.hidden-form-item {
  margin: 0 0 4px 0;

  .ant-form-item-control-input {
    display: none;
  }
}

.heatmap-table {
  .ant-table-tbody > tr.ant-table-row {
    &:hover {
      td {
        background: white;
      }

      box-shadow: 0 0 10px 2px rgba(19, 131, 185, 0.3);
      position: relative;
      z-index: 10;
    }
  }
}


.ant-menu-horizontal .hide-in-horizontal-menu {
  display: none;
}