// @import "../../theme.less";

// .data-export {
//   .export-configs-list {
//     .ant-card-body {

//       .ant-card-meta-title {
//         // color: @white;
//         font-weight: bolder;
//       }

//       // .ant-card-actions {
//       //   background: @gold-6;
//       // }
//     }
//   }
// }

.export-inactive-factor {
    cursor: pointer;
    padding: 5px;
    border-radius: 2px;
}

.export-inactive-factor:hover {
    background-color: rgb(235, 235, 235);
}

.export-active-factor {
    padding: 5px;
    border-radius: 2px;
    background-color: rgb(206, 206, 206);
}

