.holistico {
  .recent-proposals {
    .proposals-list-item {
      width: 100%;
      display: flex;
      border: 1px solid #f0f0f0;
      margin: 12px 0;
      padding: 8px 0 8px 12px;
      .menu-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        min-width: 80px;

        .ant-dropdown-trigger {
          height: calc(100% + 2 * 4);
        }
      }

      &:hover {
        background: #E6F7FFFF;
        cursor: pointer;
      }
    }
  }
}

