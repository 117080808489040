@import "../../htheme.less";

.asset-filters-form.ant-form, .holistico .asset-filters-form.ant-form {

  .ant-form-item {
    margin-bottom: 0;

    .ant-input-number {
      .ant-input-number-input-wrap {
        border-left: 7px solid rgba(24, 144, 255, 0);
        border-radius: 4px;
      }
      &:has(> .ant-input-number-input-wrap > input:not([value=""])) {
        border-color: @color-blue;
        .ant-input-number-input-wrap {
          border-color: rgba(24, 144, 255, 1);
        }
      }
    }

    .ant-input-number-sm {
      .ant-input-number-input-wrap {
        border-left: 7px solid rgba(24, 144, 255, 0);
        border-radius: 2px;
      }
      &:has(> .ant-input-number-input-wrap > input:not([value=""])) {
        border-color: @color-blue;
        .ant-input-number-input-wrap {
          border-color: rgba(24, 144, 255, 1);
        }
      }
    }
  }

    .ant-checkbox-wrapper-in-form-item {
      margin: 2px 0;
      .ant-checkbox + span {
        color: @color-grey;
        line-height: 1.2;
      }

      .ant-checkbox.ant-checkbox-checked + span {
        color: @color-grey;
        line-height: 1.2;
      }
    }

  .ant-collapse {
    .ant-collapse-item {
      margin-bottom: 4px;

      .ant-collapse-header {
        font-size: 20px;
        font-weight: 500;
        padding: 0;
        min-height: 40px;

        &:hover {
          color: @color-grey;
        }

        //&:before {
        //  content: "";
        //  width: 8px;
        //  height: 4px;
        //  background: rgba(227, 227, 227, 1.0);
        //  position: absolute;
        //  left: -24px;
        //  top: 18px;
        //  //border-bottom-right-radius: 8px;
        //  //border-top-right-radius: 8px;
        //}
      }
      .ant-collapse-content-box {
        padding: 0 0 16px 0;
      }
    }
  }

  .ant-tag.filter-tag {
    padding: 4px 8px 4px 12px;
    margin-bottom: 4px;
    margin-top: 4px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .ant-tag-close-icon {
      color: white;
      font-size: 16px;
      margin-left: 8px;

      &:hover {
        color: #e6f7ff;
      }
    }
  }

  .auxiliary-button {
    color: @color-light-grey;
    font-weight: 350;
    box-shadow: none;
    //border: none;

    &:hover {
      color: @color-blue;
    }
  }
}

.anticon.screener-favorite-icon {
  font-size: 24px;
  color: @color-light-grey;
  transition: color 0.2s ease;

  &.selected {
    color: #f5222d;
  }

  &:hover {
    font-size: 26px;
  }
}

.screener-fund-card {
  .ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #fafafa;
    background-color: #fafafa;
  }
}

